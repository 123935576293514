import IconExit from '~icons/icons/Exit.svg'

export type IconType = keyof typeof iconsMap

export const iconsMap = {
  exit: IconExit,
}

export type OrganismsSidebarHeaderProps = {
  titleText: string
  recapText?: string
  actionText?: string
  hasBackButton?: boolean
  hasCloseButton?: boolean // default true
  opensFromHeader?: boolean // changes design token based on value - true = Menu | false = Standard
  actionIcon?: IconType
  containerClass?: string
  targetLink?: string
}
