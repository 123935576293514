<script lang="ts" setup>
import type { SidebarFooterProps } from './OrganismsSidebarFooter.props'

const { secondaryText } = defineProps<SidebarFooterProps>()

const emit = defineEmits(['primary-click', 'secondary-click'])
</script>

<template>
  <div
    class="mt-md py-md gap-4 lg-custom:gap-md positive-padding z-50 flex flex-col justify-between sm:flex-row md:flex-col lg:flex-row"
    :class="[
      isBackgroundOffWhite ? 'bg-primitives-off-white' : 'bg-primitives-white',
    ]"
  >
    <AtomsCta
      v-if="secondaryText"
      anatomy="secondary"
      :aria-label="secondaryText"
      :disabled="isSecondaryDisabled"
      :loading="isSecondaryLoading"
      :success="isSecondarySuccess"
      :icon-show-default="false"
      class="w-full sm:flex-1 md:flex-none lg:flex-1"
      @click="emit('secondary-click')"
    >
      <template #label>
        {{ secondaryText }}
      </template>
    </AtomsCta>
    <MoleculesLinkWrapper
      v-if="isPrimaryLink"
      :to="primaryLinkTo"
      class="w-full sm:flex-1 md:flex-none lg:flex-1"
    >
      <template #label>
        {{ primaryText }}
      </template>
    </MoleculesLinkWrapper>
    <AtomsCta
      v-else
      :aria-label="primaryText"
      :success="isPrimarySuccess"
      :loading="isPrimaryLoading"
      :disabled="isPrimaryDisabled"
      data-testid="select-size-button"
      class="w-full sm:flex-1 md:flex-none lg:flex-1"
      @click-handler="emit('primary-click')"
    >
      <template #label>
        {{ primaryText }}
      </template>
    </AtomsCta>
  </div>
</template>
