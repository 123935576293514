<script setup lang="ts">
import type { OrganismsSidebarHeaderProps } from './OrganismsSidebarHeader.props'
import { iconsMap } from './OrganismsSidebarHeader.props'

const props = withDefaults(defineProps<OrganismsSidebarHeaderProps>(), {
  hasCloseButton: true,
})

defineEmits(['back-button-click', 'action-button-click', 'close-button-click'])

// Sidebar has different padding top
// depending on whether it's opened from a button in the header
// or from a general button elsewhere on the page
const sidebarHeaderPaddingTop = computed(() =>
  props.opensFromHeader
    ? 'pt-primitives-20 md:pt-primitives-21 lg:pt-primitives-18'
    : 'pt-8 md:pt-10 lg:pt-14'
)
</script>

<template>
  <div
    data-test-id="sidebar-header"
    class="bg-background-secondary positive-padding flex items-center gap-6 pb-6"
    :class="[sidebarHeaderPaddingTop, containerClass]"
  >
    <button
      v-if="hasBackButton"
      class="h-6 w-6"
      :aria-label="$ts('accessibility.backToPreviousSidebar')"
      @click="$emit('back-button-click')"
    >
      <DefaultIconsPrevious aria-hidden="true" />
    </button>

    <HeadlessDialogTitle
      as="div"
      class="gap-xs flex w-full min-w-0 !shrink items-baseline"
    >
      <h2 class="text-book-5 inline-block min-w-0 truncate uppercase">
        {{ titleText }}
      </h2>
      <span v-if="recapText" class="text-light-6 inline-block min-w-0 truncate">
        {{ recapText }}
      </span>
    </HeadlessDialogTitle>

    <div
      v-if="actionText || actionIcon"
      class="flex shrink-0 items-center gap-2"
    >
      <MoleculesLinkWrapper
        v-if="targetLink"
        icon-position="left"
        anatomy="link"
        :to="targetLink"
      >
        <template v-if="actionIcon" #iconLink>
          <component
            :is="iconsMap[actionIcon]"
            class="h-4 w-4"
            aria-hidden="true"
          />
        </template>
        <template #label>
          {{ actionText }}
        </template>
      </MoleculesLinkWrapper>
      <AtomsCta
        v-else
        anatomy="link"
        icon-position="left"
        @click-handler="$emit('action-button-click')"
      >
        <template v-if="actionIcon" #icon>
          <component
            :is="iconsMap[actionIcon]"
            class="h-4 w-4"
            aria-hidden="true"
          />
        </template>
        <template #label>
          {{ actionText }}
        </template>
      </AtomsCta>
    </div>

    <button
      v-if="hasCloseButton"
      :aria-label="$ts('accessibility.closeSidebar')"
      class="h-6 w-6 shrink-0"
      @click="$emit('close-button-click')"
    >
      <DefaultIconsClose aria-hidden="true" />
    </button>
  </div>
</template>
